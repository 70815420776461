import React, { FC } from 'react';

import styles from './PropertiesViewOnlyHistoricalProperties.module.scss';

export const PropertiesViewOnlyHistoricalProperties: FC = () => (
  <div className={styles.container}>
    <div className={styles.title}>Sundae Historical Sales</div>

    <div>
      Historical Sales only appear on the map.
      <br />
      Each property page includes a list of nearby properties sold on Sundae.
    </div>
  </div>
);
