import classNames from 'classnames';
import React, { FC } from 'react';

import { Paper, PaperProps } from '../Paper/Paper';

import styles from './CardNew.module.scss';

type CardNewProps = PaperProps & { variant?: 'default' | 'gray' };

export const CardNew: FC<CardNewProps> = ({
  variant = 'default',
  children,
  className,
  ...paperProps
}) => (
  <Paper
    className={classNames(className, styles.card, { [styles.bgGray]: variant === 'gray' })}
    {...paperProps}
  >
    {children}
  </Paper>
);
