import classNames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';

import { setPropertySortOrder, setPropertyPage } from '../../actions';
import {
  GetUserInfoResponseBody,
  ListPropertiesResponseBodyItem,
  SortOrder
} from '../../api/_base/generated/data-contracts';
import { BuyBoxBanner } from '../../components/BuyBoxBanner/BuyBoxBanner';
import { MembershipUpgradeCta } from '../../components/MembershipUpgradeCta/MembershipUpgradeCta';
import { PrivateFooter } from '../../components/PrivateFooter';
import { PromoBanner } from '../../components/PromoBanner/PromoBanner';
import { PropertiesViewHeader } from '../../components/PropertiesViewHeader';
import { PropertiesViewOnlyHistoricalProperties } from '../../components/PropertiesViewOnlyHistoricalProperties/PropertiesViewOnlyHistoricalProperties';
import { PropertiesViewTable } from '../../components/PropertiesViewTable';
import { PropertiesViewTableHeader } from '../../components/PropertiesViewTableHeader';
import { isBaseTier } from '../../helpers/subscriptionHelper';
import { AppDispatch } from '../../store';
import { PropertyQueryState } from '../../store/propertyQuery';
import { getUserInfo } from '../../utils/localStorage.utils';

import styles from './PropertiesView.module.scss';

interface PropertiesViewProps {
  onPropertyClick: (property: ListPropertiesResponseBodyItem) => void;
  onPropertyHover: (property: ListPropertiesResponseBodyItem) => void;
  propertiesLoaded: boolean;
  queryParamsInput: PropertyQueryState;
  properties: ListPropertiesResponseBodyItem[];
  historicalProperties: ListPropertiesResponseBodyItem[];
  onlyHistoricalProperties: boolean;
}

const PropertiesView = ({
  onPropertyClick,
  onPropertyHover,
  propertiesLoaded,
  queryParamsInput,
  properties,
  historicalProperties,
  onlyHistoricalProperties
}: PropertiesViewProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const sortChange = (field: string, initialSortOrder = SortOrder.DESC) => {
    let sortOrder = initialSortOrder;

    if (queryParamsInput.sortBy === field) {
      sortOrder = queryParamsInput.sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    }

    dispatch(setPropertySortOrder({ sortBy: field, sortOrder: sortOrder }));
  };

  const fetchMoreData = () => {
    dispatch(setPropertyPage(queryParamsInput.page + 1));
  };

  const totalRecords = properties.length + historicalProperties.length;

  return (
    <>
      <div className={styles.propertyListingFlexWrapper}>
        <PropertiesViewHeader queryParams={queryParamsInput} totalRecords={totalRecords} />
        {/* <BuyBoxBanner className={styles.buyBoxBanner} /> */}
        <PromoBanner buyBoxWrapperClassName={styles.banner} />
        {!onlyHistoricalProperties && (
          <PropertiesViewTableHeader sortChange={sortChange} queryParams={queryParamsInput} />
        )}
        <div
          className={classNames(styles.flexPropertyListingScrollWrap, {
            [styles.noBorder]: onlyHistoricalProperties
          })}
        >
          {onlyHistoricalProperties ? (
            <PropertiesViewOnlyHistoricalProperties />
          ) : (
            <PropertiesViewTable
              items={properties}
              fetchMoreData={fetchMoreData}
              totalRecords={properties.length}
              onPropertyClick={onPropertyClick}
              onPropertyHover={onPropertyHover}
              propertiesLoaded={propertiesLoaded}
            />
          )}

          <div
            className={classNames(styles.mobileFooter, {
              [styles.noMarginTop]: onlyHistoricalProperties
            })}
          >
            <PrivateFooter />
          </div>
        </div>
      </div>
      <div className={styles.desktopFooter}>
        <PrivateFooter />
      </div>
    </>
  );
};

export default PropertiesView;
