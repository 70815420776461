import React, { createContext, useContext } from 'react';

import { getUserInfo } from '../api/user/getUserInfo';
import { captureApiException } from '../logging';

const AuthenticationContext = createContext<Record<string, unknown>>({});

export const AuthenticationProvider = ({
  children
}: React.HTMLAttributes<HTMLElement>): React.ReactElement => {
  const fiveMinutes = 5 * 60 * 60 * 1000;

  // Call the getUserInfo function every five minutes, on failure the user will be logged out.
  setInterval(async () => {
    // This function will call logout() if unsuccessful.
    try {
      getUserInfo();
    } catch (error: unknown) {
      captureApiException(error);
    }
  }, fiveMinutes);

  return <AuthenticationContext.Provider value={{}}>{children}</AuthenticationContext.Provider>;
};

export const useViewportContext = (): Record<string, unknown> => useContext(AuthenticationContext);
