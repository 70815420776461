import { useCallback, useEffect } from 'react';

import { getUserInfo } from '../api/user/getUserInfo';
import { CHOOSE_ACTIVE_MARKETS_MODAL_NAME } from '../modals/MarketManagementModals/ChooseActiveMarketsModal';
import { MP_2_MODAL } from '../modals/PromotionalModals/Mp2Modal';
import { SMS_OPT_IN_MODAL } from '../modals/SmsOptInModal/SmsOptInModal';

import { useModalManager } from './useModalManager';

const SHOW_MARKET_MANAGEMENT_TEST = false;

export const useConfirmMarketsModals = () => {
  const { showModal } = useModalManager();

  const getUserShouldConfirmMarkets = useCallback(async () => {
    const user = await getUserInfo();

    const showConfirmMarkets = SHOW_MARKET_MANAGEMENT_TEST || !!user.userShouldConfirmMarkets;
    const showMp2 = !user.hasSeenMp2;
    const showSMSOptInModal = !!user.showSMSOptInModal;

    if (showSMSOptInModal) {
      showModal(SMS_OPT_IN_MODAL);
    } else if (showMp2) {
      showModal(MP_2_MODAL);
    } else if (showConfirmMarkets) {
      showModal(CHOOSE_ACTIVE_MARKETS_MODAL_NAME);
    }

    // Temporarily bypass always showing active markets modal first
    // if (showConfirmMarkets) {
    //   showModal(CHOOSE_ACTIVE_MARKETS_MODAL_NAME);
    // }
  }, [showModal]);

  useEffect(() => {
    getUserShouldConfirmMarkets();
    // show once per mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
