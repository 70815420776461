import pluralize from 'pluralize';
import React from 'react';

import { useModalManager } from '../../hooks/useModalManager';
import { MP_2_MODAL } from '../../modals/PromotionalModals/Mp2Modal';
import { PropertyQueryState } from '../../store/propertyQuery';
import { HelpIcon } from '../Icons';
import { Button } from '../design-system';

import styles from './PropertiesViewHeader.module.scss';

interface PropertiesViewHeaderProps {
  totalRecords: number;
  queryParams: PropertyQueryState;
}

export const PropertiesViewHeader = ({ totalRecords, queryParams }: PropertiesViewHeaderProps) => {
  const { showModal } = useModalManager();

  return (
    <div className={styles.pageTotalCount}>
      <div>
        <strong>Showing {totalRecords} </strong>
        {pluralize('property', totalRecords)} in{' '}
        {queryParams.regionName === 'All' ? 'the United States' : queryParams.regionName}
      </div>
      <Button
        variant="bare"
        onClick={() => {
          showModal(MP_2_MODAL);
        }}
      >
        <HelpIcon />
      </Button>
    </div>
  );
};
