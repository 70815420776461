import { useQuery } from '@tanstack/react-query';

import { api } from '../_base';

// GET /api/territories
export const getTerritoriesGroupedByState = () => {
  return api.territoryControllerTerritoryInfo({ includePropertyCount: true });
};

export const useTerritoriesGroupedByState = () => {
  return useQuery({
    queryKey: ['getTerritoriesGroupedByState'],
    queryFn: getTerritoriesGroupedByState
  });
};
