import classNames from 'classnames';
import jwt from 'jsonwebtoken';
import Link from 'next/link';
import { useRouter } from 'next/router';
import queryString, { ParsedQuery } from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import logoBusinessInsiderIcon from '../../../public/images/featuredin/logo-business-insider.svg';
import logoCnnIcon from '../../../public/images/featuredin/logo-cnn.svg';
import logoForbesIcon from '../../../public/images/featuredin/logo-forbes.svg';
import logoTechCrunchIcon from '../../../public/images/featuredin/logo-techcrunch.svg';
import logoYahooIcon from '../../../public/images/featuredin/logo-yahoo.svg';
import reviewIcon from '../../../public/images/featuredin/review-logo.svg';
import home1 from '../../../public/images/home-01.jpg';
import home2 from '../../../public/images/home-02.jpg';
import home3 from '../../../public/images/home-03.jpg';
import home4 from '../../../public/images/home-04.jpg';
import houseOnMapImage from '../../../public/images/house-on-map.png';
import MapWithMarker from '../../../public/images/map-with-mark-latest.png';
import { checkUserAvailability } from '../../api/auth';
import { isApiErrorWithErrorCode } from '../../api/user';
import buttonStyles from '../../common/buttons.module.scss';
import { SignupQuery } from '../../components/SignUp/SignUpForm';
import { useAutoApprovalToken } from '../../hooks/store/misc';
import { useModalByName } from '../../hooks/useModalByName';
import { AppDispatch } from '../../store';
import { setReferralInfo } from '../../store/misc';
import { JwtReferralProps } from '../../types/onBoarding';
import { getLoginUrlWithPropertyId } from '../../utils/url.utils';

import FeedbackCarousel from './FeedbackCarousel';
import MapAndMarker from './MapAndMarker';
import styles from './PublicHomePage.module.scss';

function PublicHome() {
  const [, showSignUpModal] = useModalByName('SignUp');
  const [, showLoginModal] = useModalByName('LoginModal');
  const dispatch = useDispatch<AppDispatch>();

  const [, setAutoApprovalToken] = useAutoApprovalToken();

  const [hash, setHash] = useState('');
  const router = useRouter();

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.hash) {
      setHash(window.location.hash.substring(1).toLowerCase());
    }
  }, []);

  useEffect(() => {
    const query: SignupQuery = queryString.parse(window.location.search) as ParsedQuery<
      keyof SignupQuery
    >;

    const showSignup = () => {
      showSignUpModal();
      setAutoApprovalToken(router.query.approvaltoken as string);
    };

    const referral = query.referral;

    if (router.pathname === '/signup' && referral) {
      try {
        const jwtReferralToken = Buffer.from(referral, 'base64').toString('utf8');
        const decodedToken = jwt.decode(jwtReferralToken, { json: true });

        const {
          first_name: firstName,
          last_name: lastName,
          email,
          phone,
          property_id: propertyId
        }: JwtReferralProps = decodedToken as JwtReferralProps;

        dispatch(
          setReferralInfo({
            propertyId,
            email,
            phone,
            firstName,
            lastName,
            referralToken: referral
          })
        );

        checkUserAvailability({
          email,
          phone
        })
          .then((data) => {
            showSignup();
          })
          .catch((error: unknown) => {
            if (isApiErrorWithErrorCode(error)) {
              const errorData = error.response.data;

              if (errorData.errorCode === 'invalid_signup') {
                // redirect to login with propertyId
                router.push(getLoginUrlWithPropertyId(propertyId));
              } else {
                showSignup();
              }
            } else {
              showSignup();
            }
          });
      } catch (err: unknown) {
        console.log('Error decoding referral token', err);
      }
    } else if (router.pathname === '/signup' || (hash && hash === 'signup')) {
      showSignup();
    } else if (router.pathname === '/login' || (hash && hash === 'login')) {
      showLoginModal();
    }
  }, [hash]);

  type Hero = {
    heading: React.ReactNode;
    cta: {
      primary: React.ReactNode;
    };
  };
  const hero: Hero = {
    heading: (
      <>
        Find, win, and close on exclusive
        <br />
        and vetted investment properties
      </>
    ),
    cta: {
      primary: 'Join for Free'
    }
  };

  type LogoList = {
    img: string;
    alt: string;
  }[];
  const logoList: LogoList = [
    {
      img: logoYahooIcon.src,
      alt: 'Yahoo'
    },
    {
      img: logoForbesIcon.src,
      alt: 'Forbes'
    },
    {
      img: logoCnnIcon.src,
      alt: 'CNN'
    },
    {
      img: logoBusinessInsiderIcon.src,
      alt: 'Business Insider'
    },
    {
      img: logoTechCrunchIcon.src,
      alt: 'TechCrunch'
    },
    {
      img: reviewIcon.src,
      alt: 'Review'
    }
  ];

  return (
    <div className={styles.home}>
      {hero && (
        <div className={styles.hero}>
          <h2 className={styles.heading}>{hero.heading}</h2>
          <div className={styles.buttonGroup}>
            <button onClick={() => showSignUpModal()} className={buttonStyles.primaryButton}>
              {hero.cta.primary}
            </button>
          </div>
        </div>
      )}

      {logoList && (
        <section className={styles.logoBanner}>
          <ul className={styles.list}>
            {logoList.map(({ img, alt }, idx) => {
              return (
                <li className={styles.item} key={`${idx}-${alt}`}>
                  <img className={styles.image} src={img} alt={alt} />
                </li>
              );
            })}
          </ul>
        </section>
      )}

      <section className={classNames(styles.featureHeading)}>
        <h2 className={styles.headText}>Homebuyers with Heart</h2>
        <p className={styles.content}>
          Join our community of homebuyers with heart and invest the better way.
        </p>
      </section>

      <div className={styles.featureSection}>
        <div className="container">
          <div className={classNames(styles.homeBox, 'row')}>
            <div className={classNames(styles.imgBox, 'col-lg-6')}>
              <img src={home1.src} alt="feature-img" className="img-fluid" />
            </div>
            <div className={classNames(styles.textContent, 'col-lg-6')}>
              <h2>Discover nationwide properties you won’t find elsewhere</h2>
              <p>
                We expertly source opportunities for you with custom filters, key insights, and all
                the property details you need to close confidently.
              </p>
            </div>
          </div>
          <div className={classNames(styles.homeBox, 'row')}>
            <div className={classNames(styles.textContent, 'col-lg-6')}>
              <h2>Place offers anytime, anywhere</h2>
              <p>
                Our desktop experience make it easy to quickly exit from one property and move on to
                the next.
              </p>
            </div>
            <div className={classNames(styles.imgBox, 'col-lg-6')}>
              <img src={home2.src} alt="feature-img" className="img-fluid" />
            </div>
          </div>
          <div className={classNames(styles.homeBox, 'row')}>
            <div className={classNames(styles.imgBox, 'col-lg-6')}>
              <img src={home3.src} alt="feature-img" className="img-fluid" />
            </div>
            <div className={classNames(styles.textContent, 'col-lg-6')}>
              <h2>Maximize your returns</h2>
              <p>
                Enjoy competitive rates, waived late fees, and close in as little as five days with
                Sundae Funding+ so you can get money out of the bank and into investments.
              </p>
            </div>
          </div>
          <div className={classNames(styles.homeBox, 'row')}>
            <div className={classNames(styles.textContent, 'col-lg-6')}>
              <h2>Level up your strategy with investment resources</h2>
              <p>
                Gain access to webinars, market reports, and your dedicated Investor Advisor to
                reach your goals faster.{' '}
              </p>
            </div>
            <div className={classNames(styles.imgBox, 'col-lg-6')}>
              <img src={home4.src} alt="feature-img" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>

      <section className={styles.explore}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <h2 className="mb-0">Explore Membership Plans and Pricing</h2>
            </div>
            <div className="col-lg-5 text-sm-right text-center">
              <div className={styles.buttonGroup}>
                <button onClick={() => showSignUpModal()} className={buttonStyles.primaryButton}>
                  Join for Free
                </button>
                <button
                  onClick={() => window.open('https://sundae.com/investor/pricing/', 'blank')}
                  className={buttonStyles.secondaryButton}
                >
                  View Plans
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={styles.mapSection}>
        <MapAndMarker />

        <img className="img-fluid d-block d-md-none mx-auto" alt="" src={MapWithMarker.src} />
        {/* <CityList /> */}
        <div className="col-md-12 my-4">
          <h3>Don’t see your market?</h3>
          <p className="mx-auto">
            We’re expanding! Sign up for updates on your area and get the latest news in real estate
            trends and developments.
          </p>
        </div>
        <button
          onClick={() => showSignUpModal()}
          className={classNames(buttonStyles.primaryButton, 'mb-3')}
        >
          Keep Me Updated
        </button>
      </section>

      <section className={styles.videoSection}>
        <div className={classNames('mb-5', styles.headText)}>How it Works</div>
        <div className={classNames(styles.videoContainer)}>
          <div className={classNames(styles.frameContainer)}>
            <iframe
              className={styles.frame}
              src="https://player.vimeo.com/video/1003808475?h=9dd08b34f3"
              title="Welcome to Sundae"
              allow="autoplay; fullscreen; picture-in-picture"
              frameBorder="0"
              allowFullScreen
            />
          </div>
        </div>
      </section>

      <section className={styles.readySec}>
        <div className={styles.headText}>Ready to get Started? </div>
        <span className="mx-auto mb-4">
          Our cost-free signup could have you placing offers in minutes.{' '}
        </span>
        <button
          onClick={() => showSignUpModal()}
          className={classNames(buttonStyles.primaryButton, 'mt-sm-3 mt-0')}
        >
          Sign Up Now
        </button>
        <p className="mt-3">
          <b>Already registered?</b>{' '}
          <Link href={'/'}>
            <a onClick={() => showLoginModal()}>Click here</a>
          </Link>
        </p>
      </section>

      <section className={styles.feedbackSec}>
        <div className={classNames('mb-5', styles.headText)}>What Investors are Saying</div>
        <FeedbackCarousel />
      </section>

      <div className={styles.houseMap}>
        <div className={classNames('mt-5', styles.headText)}>Your Next Opportunity is Waiting.</div>
        <button
          onClick={() => showSignUpModal()}
          className={classNames(buttonStyles.primaryButton, 'mb-3', 'mt-4')}
        >
          Join for Free
        </button>
        <p>
          <b>Already registered?</b>{' '}
          <Link href={'/'}>
            <a onClick={() => showLoginModal()}>Click here</a>
          </Link>
        </p>
        <div className="col-12 col-md-8 offset-md-2">
          <img className="mt-4 img-fluid" src={houseOnMapImage.src} />
        </div>
      </div>
    </div>
  );
}

export default PublicHome;
