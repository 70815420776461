import classNames from 'classnames';
import Link from 'next/link';
import React, { FC, HTMLAttributes } from 'react';

import { BuyBoxSimpleIcon } from '../Icons';

import styles from './BuyBoxTableFooter.module.scss';

type BuyBoxTableFooterProps = HTMLAttributes<HTMLDivElement>;

export const BuyBoxTableFooter: FC<BuyBoxTableFooterProps> = ({ className, ...props }) => (
  <div className={classNames(styles.container, className)} {...props}>
    <BuyBoxSimpleIcon className={styles.icon} />
    <span>
      Matched Buy Box Criteria –{' '}
      <Link href="/dashboard/buy-box">
        <a className={styles.link}>Edit Buy Box</a>
      </Link>
    </span>
  </div>
);
