import classNames from 'classnames';
import React, { FC } from 'react';

import logoIcon1 from '../../../public/favicon_56.svg';
import emailIcon from '../../../public/icons/email-round.svg';
import facebookIcon from '../../../public/icons/facebook-round.svg';
import instagramIcon from '../../../public/icons/instagram-round.svg';
import twitterIcon from '../../../public/icons/twitter-round.svg';
import youtubeIcon from '../../../public/icons/youtube-round.svg';
import { PrivateFooter } from '../PrivateFooter';

import styles from './PublicFooter.module.scss';

export const PublicFooter: FC = () => (
  <>
    <div className={classNames('container-fluid', 'text-center', 'pt-5', 'whiteBg')}>
      <div>
        <div className={styles.footerMedia}>
          <div className={styles.footerLogo}>
            <img
              src={logoIcon1.src}
              width={logoIcon1.width}
              height={logoIcon1.height}
              alt=""
              className="img-fluid"
            />
          </div>
          <ul className={styles.footerLinks1}>
            <li>
              <a href="https://marketplace.sundae.com/" target="_blank" rel="noopener noreferrer">
                Marketplace
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://sundae.com/investor/pricing"
                rel="noopener noreferrer"
              >
                Pricing
              </a>
            </li>
            <li>
              <a href="https://sundae.com/blog/investor/" target="_blank" rel="noopener noreferrer">
                Investor Blog
              </a>
            </li>
            <li>
              <a target="_blank" href="https://sundae.com/investors/faq/" rel="noopener noreferrer">
                FAQ
              </a>
            </li>
            <li>
              <a target="_blank" href="https://sundae.com/about-us" rel="noopener noreferrer">
                About
              </a>
            </li>
            <li>
              <a target="_blank" rel="noopener noreferrer" href="https://sundae.com/careers">
                Careers
              </a>
            </li>
          </ul>
          <ul className={styles.socialIcons}>
            <li>
              <a href="https://www.facebook.com/SundaeHQ" target="_blank" rel="noopener noreferrer">
                <img src={facebookIcon.src} alt="facebook" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/SundaeHQ" target="_blank" rel="noopener noreferrer">
                <img src={twitterIcon.src} alt="twitter" />
              </a>
            </li>
            <li>
              <a href="mailto:info@sundae.com">
                <img src={emailIcon.src} alt="email" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/sundaehq/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagramIcon.src} alt="instagram" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCcYk3-zCAMogCmTowaNqESg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={youtubeIcon.src} alt="youtube" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <PrivateFooter />
  </>
);
