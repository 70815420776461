import classNames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';

import styles from './Paper.module.scss';

export type PaperProps = HTMLAttributes<HTMLDivElement> & {
  elevation?: number;
  hoverElevation?: number;
  hoverDarkBorder?: boolean;
};

export const Paper: FC<PaperProps> = ({
  className,
  elevation = 1,
  hoverElevation,
  hoverDarkBorder,
  ...props
}) => (
  <div
    {...props}
    className={classNames(className, styles.paper, {
      [styles.elevation1]: elevation === 1,
      [styles.elevation2]: elevation === 2,
      [styles.hoverElevation1]: hoverElevation === 1,
      [styles.hoverElevation2]: hoverElevation === 2,
      [styles.hoverDarkBorder]: hoverDarkBorder
    })}
  />
);
