import React, { useEffect, useState } from 'react';

import { Layout } from '../../components/Layout';
import { CondensedPrivateFooter } from '../../components/PrivateFooter';
import { PublicFooter } from '../../components/PublicFooter';
import { PublicHeader } from '../../components/PublicHeader';
import { AuthenticationProvider } from '../../contexts/AuthenticationProvider';
import { useIsUserAuthenticated } from '../../hooks/localStorage';
import InvestorContainer from '../InvestorContainer';
import MapViewFeed from '../MapViewFeed';
import PublicHome from '../PublicHomePage';

export const HomePageLayout = () => {
  const [loading, setLoading] = useState(true);
  const isAuthenticated = useIsUserAuthenticated();
  useEffect(() => {
    setLoading(false);
  }, []);
  if (loading) return null;

  return (
    <>
      {isAuthenticated && (
        <>
          <AuthenticationProvider>
            <div className={'layoutTop'}>
              <PublicHeader isAuthenticated={isAuthenticated} />
              <Layout enableFullView>
                <MapViewFeed />
              </Layout>
            </div>
            <CondensedPrivateFooter isHiddenDesktop={true} />
          </AuthenticationProvider>
        </>
      )}
      {!isAuthenticated && (
        <InvestorContainer>
          <PublicHeader isAuthenticated={isAuthenticated} />
          <PublicHome />
          <PublicFooter />
        </InvestorContainer>
      )}
    </>
  );
};
