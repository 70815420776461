import classNames from 'classnames';
import React, { useState } from 'react';

import { CardNew } from '../CardNew/CardNew';
import { ArrowDownIcon } from '../Icons';

import styles from './ExpandingListingBanner.module.scss';

interface ExpandingListingBannerProps {
  buyBoxWrapperClassName?: string;
  bannerChildren?: React.ReactNode;
  hiddenChildren?: React.ReactNode;
  HeaderIcon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
}

export const ExpandingListingBanner = ({
  buyBoxWrapperClassName,
  bannerChildren,
  HeaderIcon,
  hiddenChildren
}: ExpandingListingBannerProps) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div
      className={classNames(styles.anchor, buyBoxWrapperClassName, {
        [styles.anchorExpanded]: expanded
      })}
    >
      <CardNew
        variant="gray"
        elevation={1}
        hoverElevation={2}
        hoverDarkBorder
        onClick={handleToggleExpand}
      >
        <div className={classNames(styles.container, { [styles.expanded]: expanded })}>
          {!!HeaderIcon && <HeaderIcon className={styles.headerIcon} />}
          <div className={styles.parentBannerText}>{bannerChildren}</div>
          <ArrowDownIcon className={styles.arrowDownIcon} />
        </div>

        {expanded && <div>{hiddenChildren}</div>}
      </CardNew>
    </div>
  );
};
