import 'bootstrap/dist/css/bootstrap.min.css';
import classNames from 'classnames';
import GoogleMapReact, { Coords } from 'google-map-react';
import React, { FC, useEffect, useRef, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { useSelector } from 'react-redux';
import { Col, PopoverBody, Row, Spinner, UncontrolledPopover } from 'reactstrap';
import useSupercluster from 'use-supercluster';

import {
  ListPropertiesResponseBodyItem,
  PropertyStatus
} from '../../api/_base/generated/data-contracts';
import { PropertyPinIcon } from '../../components/Icons';
import { MapPropertyCard } from '../../components/MapPropertyCard';
import { MapControls } from '../../components/UI';
import { mapStyles } from '../../helpers/marketPlaceViewHelpers';
import { usePropertyDetail } from '../../hooks/usePropertyDetail';
import { getMapPosition } from '../../store/mapPosition';

import styles from './MapView.module.scss';

interface MapViewProps {
  properties: ListPropertiesResponseBodyItem[];
  historicalProperties: ListPropertiesResponseBodyItem[];
  selectedProperty: ListPropertiesResponseBodyItem | undefined;
}
interface IGoogleMapHandle extends GoogleMapReact {
  setZoom: (zoom: number) => void;
  setMapTypeId: (mapType: string) => void;
  panTo: ({ lat, lng }: { lat: number; lng: number }) => void;
}

const Marker: FC<MarkerProps & Coords> = ({ children, className }) => (
  <div className={className}>{children}</div>
);

const markersZIndexOrder: Array<PropertyStatus | undefined> = [
  PropertyStatus.ACTIVE,
  PropertyStatus.COMINGSOON,
  PropertyStatus.PENDING,
  PropertyStatus.CLOSED,
  undefined
];

const getPoints = (properties: ListPropertiesResponseBodyItem[]) => {
  if (!properties || properties.length === 0) {
    return [];
  }

  return properties
    .filter((property) => property.propertyStatus !== PropertyStatus.COMINGSOON)
    .sort(
      (a, b) =>
        markersZIndexOrder.indexOf(b.propertyStatus) - markersZIndexOrder.indexOf(a.propertyStatus)
    )
    .map((property) => ({
      type: 'Feature',
      properties: {
        cluster: false,
        propertyId: property.id,
        price: property.price,
        closed: property.propertyStatus === PropertyStatus.CLOSED,
        propertyData: property
      },
      geometry: {
        type: 'Point',
        coordinates: [property.longitude || 0, property.latitude || 0]
      }
    }));
};

const ClosedPropertyPin: FC = () => (
  <div
    style={{
      position: 'absolute',
      width: '16px',
      height: '16px',
      transform: 'translate(-50%, -50%)',
      background: ' #828282',
      border: '2px solid #FFFFFF',
      boxSizing: 'border-box',
      borderRadius: '50%'
    }}
  ></div>
);

type MarkerProps = {
  children: React.ReactNode;
  className?: string;
};

export const MapView: FC<MapViewProps> = ({
  properties,
  selectedProperty,
  historicalProperties
}) => {
  const [currentProperty, loadPropertyDetail] = usePropertyDetail();

  const mapPosition = useSelector(getMapPosition);

  const mapRef = useRef<IGoogleMapHandle | null>(null);
  const [bounds, setBounds] = useState<number[]>();
  const [zoom, setZoom] = useState(mapPosition.zoom);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setZoom(mapPosition.zoom);
    }
  }, [mapPosition]);

  const toggleMapControl = (hybrid: boolean) => {
    if (mapRef.current) {
      mapRef.current.setMapTypeId(hybrid ? 'roadmap' : 'hybrid');
    }
  };

  const points = getPoints([...historicalProperties, ...properties]);

  const handleApiLoaded = (map: IGoogleMapHandle) => {
    mapRef.current = map;
  };

  const createMapOptions = () => {
    return {
      streetViewControl: true,
      fullscreenControl: false,
      styles: mapStyles,
      DirectionsTravelMode: true,
      mapTypeControl: false,
      zoomControl: true,
      gestureHandling: 'greedy'
    };
  };

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 100, maxZoom: 20 }
  });

  return (
    <div className={styles.googleMapReactWrapper}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBTmJP5EEgdE8Xn592c3ZYKFxv46Q5sMMM' }}
        defaultCenter={{ lat: mapPosition.lat, lng: mapPosition.long }}
        defaultZoom={3.5}
        center={{ lat: mapPosition.lat, lng: mapPosition.long }}
        zoom={mapPosition.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          handleApiLoaded(map);
        }}
        options={createMapOptions}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
        }}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude]: number[] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } = cluster.properties;

          if (isCluster) {
            return (
              <Marker key={`cluster-${cluster.id}`} lat={latitude} lng={longitude}>
                <div
                  className={styles.clusterMarker}
                  style={{
                    width: `${10 + (pointCount / points.length) * 20}px`,
                    height: `${10 + (pointCount / points.length) * 20}px`
                  }}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      20
                    );
                    mapRef.current?.setZoom(expansionZoom);
                    mapRef.current?.panTo({ lat: latitude, lng: longitude });
                  }}
                >
                  {pointCount}
                </div>
              </Marker>
            );
          }

          return (
            <Marker
              key={`property-${cluster.properties.propertyId}`}
              lat={latitude}
              lng={longitude}
            >
              <div className="map-card-wrapper">
                <div
                  id={'pop' + cluster.properties.propertyId}
                  className={classNames(styles.mapMarkerSingle, {
                    [styles.selected]:
                      selectedProperty && selectedProperty.id === cluster.properties.id
                  })}
                  onClick={() => {
                    loadPropertyDetail(
                      cluster.properties.propertyId || '',
                      cluster.properties.propertyData
                    );
                  }}
                >
                  {cluster.properties.closed ? <ClosedPropertyPin /> : <PropertyPinIcon />}
                </div>
                <UncontrolledPopover
                  className={classNames('mapViewWrapper', 'popup-property')}
                  trigger="legacy"
                  placement="top"
                  target={'pop' + cluster.properties.propertyId}
                >
                  {!currentProperty && (
                    <Row>
                      <Col className="text-center mt-5">
                        <Spinner style={{ width: '3rem', height: '3rem' }} type="grow" />
                      </Col>
                    </Row>
                  )}
                  {currentProperty && (
                    <PopoverBody>
                      <MapPropertyCard property={currentProperty}></MapPropertyCard>
                    </PopoverBody>
                  )}
                </UncontrolledPopover>
              </div>
            </Marker>
          );
        })}
      </GoogleMapReact>
      {mapRef.current && <MapControls toggleMapControl={toggleMapControl} />}
    </div>
  );
};
