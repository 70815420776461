import React from 'react';
import Carousel from 'react-multi-carousel';

import { testimonials, Testimonial } from '../../helpers/constants';

import styles from './PublicHomePage.module.scss';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1600 },
    items: 4,
    paritialVisibilityGutter: 40
  },
  tablet: {
    breakpoint: { max: 1600, min: 800 },
    items: 2,
    paritialVisibilityGutter: 30
  },
  mobile: {
    breakpoint: { max: 800, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30
  }
};

interface FeedCardProps {
  card: Testimonial;
}

const FeedCard = ({ card }: FeedCardProps) => {
  return (
    <div className={styles.feedCard}>
      <div className={styles.message}>{card.message}</div>
      <p>{card.name}</p>
    </div>
  );
};

const FeedbackCarousel = () => {
  return (
    <div>
      <Carousel
        ssr
        partialVisible
        itemClass={styles.cardItem}
        responsive={responsive}
        arrows={false}
        swipeable
        showDots
        infinite={true}
      >
        {testimonials.map((item, index) => (
          <FeedCard key={index} card={item} />
        ))}
      </Carousel>
    </div>
  );
};

export default FeedbackCarousel;
