import classNames from 'classnames';
import add from 'lodash/add';
import find from 'lodash/find';
import mergeWith from 'lodash/mergeWith';
import without from 'lodash/without';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import mapPinIcon from '../../../public/icons/mappin.svg';
import tableGrid from '../../../public/icons/table-grid.svg';
import { setMapPosition, setPropertyRegion } from '../../actions';
import {
  GetUserInfoResponseBody,
  ListPropertiesResponseBodyItem,
  PropertyStatus,
  StateTerritories,
  TerritoryWithPropertyCounts
} from '../../api/_base/generated/data-contracts';
import { getProperties, GetPropertiesQuery } from '../../api/properties/getProperties';
import { MapFilters, OfferSummary } from '../../components/UI';
import { useConfirmMarketsModals } from '../../hooks/useConfirmMarketsModals';
import { useHasMembership } from '../../hooks/useHasMembership';
import { useTerritories } from '../../hooks/useTerritories';
import { useUpdatePropertyOffers } from '../../hooks/useUpdatePropertyOffers';
import { captureException } from '../../logging';
import { AppDispatch } from '../../store';
import { selectPropertyQueryState } from '../../store/propertyQuery';
import { getShowOfferSummary, getUserInfo } from '../../utils/localStorage.utils';
import { MapView } from '../MapView/MapView';
import PropertiesView from '../PropertiesView';

import styles from './MapViewFeed.module.scss';

type RegionCountsType = {
  numberOfActiveProperties: number;
  numberOfComingSoonProperties: number;
  numberOfPendingProperties: number;
};

export type RegionPropertyCountsType = {
  [regionName: string]: RegionCountsType;
};

const TABLE_VIEW = 'tableView';
const MAP_VIEW = 'mapView';

type ViewType = typeof TABLE_VIEW | typeof MAP_VIEW;

const MapViewFeed = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [properties, setProperties] = useState<ListPropertiesResponseBodyItem[]>([]);
  const [historicalProperties, setHistoricalProperties] = useState<
    ListPropertiesResponseBodyItem[]
  >([]);
  const [viewType, setViewType] = useState<ViewType>(MAP_VIEW);

  useEffect(() => {
    // Scope class for HelpScout render.
    // @todo: find better way to conditionally display HelpScout. Ideally URL params but explore other options.
    if (viewType === MAP_VIEW) {
      document.body.classList.remove(TABLE_VIEW);
      document.body.classList.add(MAP_VIEW);
    } else if (viewType === TABLE_VIEW) {
      document.body.classList.remove(MAP_VIEW);
      document.body.classList.add(TABLE_VIEW);
    }

    const cleanup = () => {
      if (viewType === MAP_VIEW) {
        document.body.classList.remove(MAP_VIEW);
      } else if (viewType === TABLE_VIEW) {
        document.body.classList.remove(TABLE_VIEW);
      }
    };

    return cleanup;
  }, [viewType]);

  const { getAndCacheTerritories } = useTerritories();
  useConfirmMarketsModals();

  const user: GetUserInfoResponseBody | undefined = getUserInfo();

  const [selectedProperty, setSelectedProperty] = useState<ListPropertiesResponseBodyItem>();
  const [territoryTally, setTerritoryTally] = useState({});
  const [propertiesLoaded, setPropertiesLoaded] = useState(false);
  const [territoryMatched, setTerritoryMatched] = useState(false);
  const [region, setRegion] = useState<TerritoryWithPropertyCounts | undefined>(undefined);

  const [territories, setTerritories] = useState<StateTerritories[]>([]);
  const propertyQueryState = useSelector(selectPropertyQueryState);

  const showOfferSummary: boolean = getShowOfferSummary();

  const onlyHistoricalProperties =
    propertyQueryState.statuses.length === 1 &&
    propertyQueryState.statuses.includes(PropertyStatus.CLOSED);

  const regionPropertyCounts: RegionPropertyCountsType = {
    All: {
      numberOfActiveProperties: 0,
      numberOfComingSoonProperties: 0,
      numberOfPendingProperties: 0
    }
  };

  useEffect(() => {
    getAndCacheTerritories()
      .then((data: StateTerritories[]) => {
        const allTerr: StateTerritories[] = [
          {
            name: 'All',
            abbr: 'All',
            regions: [
              {
                name: 'All',
                isActive: true,
                latitude: 39.0997,
                longitude: -94.5786,
                territoryId: 'All',
                isComingSoon: false,
                isInActive: false
              }
            ]
          }
        ];

        const sortState = data.sort((a: StateTerritories, b: StateTerritories) => {
          if (a.name < b.name) {
            return -1;
          }

          if (a.name > b.name) {
            return 1;
          }

          return 0;
        });
        sortState.forEach((state: StateTerritories) => {
          state.regions = state.regions.sort((a, b) => {
            if (!a.name || !b.name) return 0;
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;

            return 0;
          });
        });
        const territoryAgg = allTerr.concat(sortState);

        setTerritories(territoryAgg);

        data.forEach((territory: StateTerritories) => {
          territory.regions.forEach((region: TerritoryWithPropertyCounts) => {
            const {
              numberOfComingSoonProperties = 0,
              numberOfActiveProperties = 0,
              numberOfPendingProperties = 0
            } = region;

            const regionCounts: RegionCountsType = {
              numberOfComingSoonProperties,
              numberOfActiveProperties,
              numberOfPendingProperties
            };

            regionPropertyCounts[region.territoryId!] = regionCounts;

            // Add region count to all counts
            mergeWith(regionPropertyCounts.All, regionCounts, add);
          });
        });

        setTerritoryTally(regionPropertyCounts);
      })
      .catch((error: unknown) => {
        captureException(error);

        if (error instanceof Error) {
          // eslint-disable-next-line no-console
          console.error(error.message);
        }
      });
  }, []);

  useUpdatePropertyOffers();

  useEffect(() => {
    if (region) {
      dispatch(
        setPropertyRegion({
          territoryId: region.territoryId!,
          regionName: region.name!
        })
      );
      dispatch(
        setMapPosition({
          lat: region.latitude!,
          long: region.longitude!,
          zoom: 9
        })
      );
    }
  }, [dispatch, region]);

  useEffect(() => {
    if (user && user.onboardingPrimaryOperatingMarket) {
      if (territories.length > 0) {
        find(territories, (item: StateTerritories) => {
          const marketRegion = item.regions.find(
            (e) => e.territoryId === user.onboardingPrimaryOperatingMarket
          );
          if (marketRegion) setRegion(marketRegion);

          return marketRegion;
        });
        setTimeout(() => {
          setTerritoryMatched(true);
        }, 100);
      }
    } else {
      setTimeout(() => {
        setTerritoryMatched(true);
      }, 100);
    }
  }, [dispatch, territories, user]);

  useEffect(() => {
    if (territoryMatched) {
      const { territoryId } = propertyQueryState;

      const propertyStatusWithoutClosed = without(
        propertyQueryState.statuses,
        PropertyStatus.CLOSED
      );

      const skipLoadingClosed = !propertyQueryState.statuses.includes(PropertyStatus.CLOSED);
      const skipLoadingNotClosed = propertyStatusWithoutClosed.length === 0;

      if (skipLoadingNotClosed) {
        setProperties([]);
        setPropertiesLoaded(true);
      } else {
        const params: GetPropertiesQuery = {
          propertyStatus: propertyStatusWithoutClosed.join(',') as PropertyStatus,
          // limit: propertyQueryState.size,
          // offset: propertyQueryState.page,
          sortBy: propertyQueryState.sortBy,
          sortOrder: propertyQueryState.sortOrder
        };

        if (territoryId !== 'All') {
          params.territory = territoryId;
        }

        setPropertiesLoaded(false);
        getProperties(params)
          .then((resp) => {
            const properties = resp.data.filter((p) => p.territoryId !== null);
            setProperties(properties);
            setPropertiesLoaded(true);
          })
          .catch((error: unknown) => {
            captureException(error);

            if (error instanceof Error) {
              // eslint-disable-next-line no-console
              console.error(error.message);
            }
          });
      }

      if (skipLoadingClosed) {
        setHistoricalProperties([]);
      } else {
        const nowMinusOneYear = Date.now() - 1000 * 60 * 60 * 24 * 365;
        const historicalParams: GetPropertiesQuery = {
          propertyStatus: PropertyStatus.CLOSED
          // dateField: 'publishDate',
          // startDate: nowMinusOneYear.toString(),
          // hidePropertiesFromInactiveMarkets: true,
          // limit: 1000,
          // offset: 0
        };
        getProperties(historicalParams)
          .then((resp) => {
            const properties = resp.data.filter((p) => p.territoryId !== null);
            setHistoricalProperties(properties);
          })
          .catch((error: unknown) => {
            captureException(error);

            if (error instanceof Error) {
              // eslint-disable-next-line no-console
              console.error(error.message);
            }
          });
      }
    }
  }, [propertyQueryState, territoryMatched]);

  const toggleViewOnMobile = () => {
    setViewType(viewType === TABLE_VIEW ? MAP_VIEW : TABLE_VIEW);
  };

  const handlePropertySelection = (property: ListPropertiesResponseBodyItem) => {
    const mapProperty = properties.find((e) => e.publishId === property.publishId);

    if (mapProperty && mapProperty.latitude && mapProperty.longitude) {
      setSelectedProperty(mapProperty);
    }
  };

  const handlePropertyClick = (property: ListPropertiesResponseBodyItem) => {
    setSelectedProperty(property);
    window.open(`${window.location.origin}/feed/${property.id}`);
  };

  return (
    <>
      <div className={styles.mapWrapper}>
        <MapFilters
          key={propertyQueryState.regionName}
          queryParamsInput={propertyQueryState}
          territories={territories}
          territoryTally={territoryTally}
          viewType={viewType}
          hidePropertyCounter={onlyHistoricalProperties}
        />
        <OfferSummary offerSummaryPreference={showOfferSummary} />
        <div className={styles.mapview}>
          <div
            className={classNames(styles.mapSection, {
              [styles.hiddenMobile]: viewType === TABLE_VIEW
            })}
          >
            <MapView
              selectedProperty={selectedProperty}
              properties={properties}
              historicalProperties={historicalProperties}
            />
          </div>

          <div
            className={classNames(styles.propertyListings, {
              [styles.hiddenMobile]: viewType === MAP_VIEW
            })}
          >
            {territoryMatched && (
              <PropertiesView
                queryParamsInput={propertyQueryState}
                propertiesLoaded={propertiesLoaded}
                properties={properties}
                historicalProperties={historicalProperties}
                onPropertyClick={(property) => handlePropertyClick(property)}
                onPropertyHover={(property) => handlePropertySelection(property)}
                onlyHistoricalProperties={onlyHistoricalProperties}
              />
            )}
          </div>
        </div>
        <button onClick={() => toggleViewOnMobile()} className={styles.mobileToggleviewButton}>
          <img
            width="23"
            src={viewType === MAP_VIEW ? tableGrid.src : mapPinIcon.src}
            alt="Map View/ Table View Icon"
          />
          <span>{`${viewType === MAP_VIEW ? 'List' : 'Map'}`}</span>
        </button>
      </div>
    </>
  );
};

export default MapViewFeed;
