import React from 'react';

import { ExpandingListingBanner } from '../ExpandingListingBanner/ExpandingListingBanner';
import { BannerPromoIcon, CherryLaneIcon, SundaeFundingIcon } from '../Icons';

import styles from './PromoBanner.module.scss';

export const PromoBanner = ({ ...props }) => {
  const bannerShownChildren = (
    <>
      <h5>Save money on your next deal!</h5>
      <p>Learn how Sundae Funding and Cherry Lane Escrow can help</p>
    </>
  );

  const hiddenChildren = (
    <>
      <div
        className={styles.bannerSection}
        onClick={(e) => e.stopPropagation()}
        id="promoFundingSection"
      >
        <div>
          <SundaeFundingIcon />
        </div>
        <div>
          Seamlessly finance your next property with low, competitive rates, and a smooth
          pre-approval and underwriting process. Sundae will even cover some of the additional fees
          associated with your transaction. <br />
          <br />
          Available for all Sundae properties and non-Sundae properties in CA, WA, TX, CO, GA, TN,
          NC, SC <br /> <br />
          For more information, contact <a href="mailto:funding@sundae.com">funding@sundae.com.</a>
        </div>
      </div>
      <hr className={styles.lineBreak} />
      <div
        className={styles.bannerSection}
        onClick={(e) => e.stopPropagation()}
        id="promoCherryLaneSection"
      >
        <div>
          <CherryLaneIcon />
        </div>
        <div>
          Are you selling your rehabbed property soon? Enjoy reduced fees and an easy process with
          Sundae&apos;s escrow service. Cherry Lane Escrow can make your next resale transaction
          smoother. <br />
          <br />
          Available for all Sundae properties and non-Sundae properties in California
          <br />
          <br />
          For more information, contact{' '}
          <a href="mailto:admin@cherrylaneescrow.com">admin@cherrylaneescrow.com.</a>
        </div>
      </div>
    </>
  );

  return (
    <ExpandingListingBanner
      HeaderIcon={BannerPromoIcon}
      bannerChildren={bannerShownChildren}
      hiddenChildren={hiddenChildren}
      {...props}
    />
  );
};
