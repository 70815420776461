import classNames from 'classnames';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Col, Row } from 'reactstrap';
import Spinner from 'reactstrap/lib/Spinner';

import {
  ListPropertiesResponseBodyItem,
  ListingListWithOfferEntryApiModel
} from '../../api/_base/generated/data-contracts';
import { BuyBoxTableFooter } from '../BuyBoxTableFooter/BuyBoxTableFooter';
import { LoaderView, PropertyRow } from '../UI';

import styles from './PropertiesViewTable.module.scss';

interface PropertiesViewTableProps {
  items: ListPropertiesResponseBodyItem[];
  fetchMoreData: () => void;
  totalRecords: number;
  onPropertyHover: (property: ListPropertiesResponseBodyItem) => void;
  onPropertyClick: (property: ListPropertiesResponseBodyItem) => void;
  propertiesLoaded: boolean;
}

export const PropertiesViewTable = ({
  items,
  fetchMoreData,
  totalRecords = 0,
  onPropertyHover,
  onPropertyClick,
  propertiesLoaded
}: PropertiesViewTableProps) => {
  const onFavProperty = (
    property: ListPropertiesResponseBodyItem | ListingListWithOfferEntryApiModel,
    favorite: boolean
  ) => {
    property.isFavorite = favorite;
  };

  return (
    <InfiniteScroll
      dataLength={items ? items.length : 0}
      next={fetchMoreData}
      hasMore={items ? items.length < totalRecords : false}
      scrollThreshold="200px"
      loader={<LoaderView />}
      className={classNames(['inc-pgv-scroll', styles.gridHeight])}
      style={{ overflowX: 'hidden', paddingBottom: '0px' }}
      endMessage={propertiesLoaded && <BuyBoxTableFooter />}
    >
      {propertiesLoaded && (
        <>
          {items && items.length > 0
            ? items.map((property) => (
                <PropertyRow
                  onFavProperty={(property, favorite) => onFavProperty(property, favorite)}
                  onClick={(property) => onPropertyClick(property)}
                  onHover={(property) => onPropertyHover(property)}
                  key={property.publishId}
                  property={property}
                />
              ))
            : null}
        </>
      )}

      {!propertiesLoaded && (
        <Row>
          <Col className="text-center mt-3 mb-5">
            <Spinner style={{ width: '2rem', height: '2rem' }} type="grow" />
          </Col>
        </Row>
      )}
    </InfiniteScroll>
  );
};
