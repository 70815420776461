import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

import USAMarketMapImage from '../../../public/images/map-us-market.png';
import { MapMarkerIcon } from '../../components/Icons';
import { markerData } from '../../helpers/constants';

import styles from './PublicHomePage.module.scss';

const MapAndMarker = () => {
  const [marker, setMarker] = useState('');

  return (
    <>
      <div className={styles.headText}>Browse New Opportunities Daily</div>
      <span className={styles.headTextP}>
        Take advantage of an ongoing supply of new inventory across the nation in a competitive
        market.
      </span>
      <div className={styles.markerMap} aria-hidden="true">
        <img className={styles.emptyMap} alt="" src={USAMarketMapImage.src} />
        {markerData.map((mark) => {
          return (
            <React.Fragment key={mark.id}>
              <MapMarkerIcon
                key={mark.id}
                id={mark.id}
                className={styles.marker}
                style={{ left: mark.left, top: mark.top }}
                onMouseLeave={() => setMarker('')}
                tabIndex={-1}
              />
              <Tooltip
                className="cityTooltip"
                placement="top"
                isOpen={mark.id === marker}
                target={mark.id}
                toggle={() => setMarker(mark.id)}
              >
                {mark.name}
              </Tooltip>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

export default MapAndMarker;
