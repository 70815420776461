import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTerritoriesGroupedByState } from '../api/territories/getTerritoriesGroupedByState';
import { selectTerritoryState, setTerritoriesAction } from '../store/territory';

import { useIsUserAuthenticated } from './localStorage';

export const useTerritories = () => {
  const isAuthenticated = useIsUserAuthenticated();
  const territoryState = useSelector(selectTerritoryState);
  const territories = isAuthenticated ? territoryState.territories : [];
  const dispatch = useDispatch();

  const getAndCacheTerritories = async () => {
    const response = await getTerritoriesGroupedByState();
    const data = response.data.data;
    dispatch(setTerritoriesAction(data));

    return data;
  };

  useEffect(() => {
    if (isAuthenticated) {
      getAndCacheTerritories();
    }
  }, [isAuthenticated]);

  return {
    territories,
    getAndCacheTerritories
  };
};
